import React from 'react';

export default class ChangesetCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { changesets: 0 };
    this._addChangeset = this._addChangeset.bind(this);
  }

  render() {
    return (
      <h1><span>CHANGESETS:</span> <span className="odometer">{this.state.changesets}</span></h1>
    );
  }

  componentDidMount() {
    this.props.service.register(this._addChangeset);
  }

  componentWillUnmount() {
    this.props.service.unregister(this._addChangeset);
  }

  _addChangeset(changeset) {
    this.setState((state) => ({
      changesets: Math.max(state.changesets, parseInt(changeset.id))
    }));
  }
}
