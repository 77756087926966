import React from 'react';

import ChangesetRow from './changeset-row.jsx';


export default class ChangesetList extends React.Component {
  constructor(props) {
    super(props);
    this._onChangesetAdded = this._onChangesetAdded.bind(this);
    this.state = {
      changesets: []
    };
  }

  render() {
    let changesets = this.state.changesets.map((changeset) =>
      <ChangesetRow changeset={changeset} key={changeset.id}/>
    );
    return (
      <div id="changeset-list" className={this.props.shown ? 'changeset-list-shown' : ''}>
        {changesets}
      </div>
    );
  }

  componentDidMount() {
    this.props.service.register(this._onChangesetAdded);
  }

  componentWillUnmount() {
    this.props.service.unregister(this._onChangesetAdded);
  }

  _onChangesetAdded(changeset) {
    this.setState((state) => {
      let changesets = state.changesets.splice(0);
      changesets.unshift(changeset);

      // clear old changesets
      while (changesets.length > 100) {
        changesets.pop();
      }

      return { changesets };
    });
  }
}
