import React from 'react';
import ChangesetCounter from './changeset-counter.jsx';

export default function Headerbar(props) {
  return (
    <nav id="headerbar">
      <span>
        <img src="logo192.png" alt="" />
        <h1>OSM In Realtime</h1>
        <span className="spacer mobile-only"></span>
        <button id="about-button"
                onClick={props.showAboutDialog}
                aria-label="About OSM In Realtime">
                ?
        </button>
      </span>
      <div className="spacer"></div>
      <span>
        <ChangesetCounter service={props.service}/>
        <div className="spacer mobile-only"></div>
        <button id="show-changesets-button" className="mobile-only" onClick={props.toggleChangesetList}/>
      </span>
    </nav>
  );
}
