import React from 'react';

export default class About extends React.Component {
  render() {
    return (
      <div className={`dialog-wrapper ${this.props.shown ? 'dialog-shown' : ''}`} onClick={this.props.hideAboutDialog}>
        <div className="dialog" onClick={(e) => e.stopPropagation()}>
          <p><strong>OSM In Realtime</strong> is a simple visualization of the changes
          made to <a href="https://openstreetmap.org" rel="noreferrer" target="_blank">OpenStreetMap</a> as they happen.</p>

          <h2>What Is OpenStreetMap?</h2>
          OSM is a worldwide map created and edited by volunteers. It aims to
          be a free, high quality data source that can be used in new and
          creative ways. <a href="https://wiki.openstreetmap.org/wiki/Getting_Involved" rel="noreferrer" target="_blank">You can join! Mapping is a lot of fun.</a>

          <h2>How It Works</h2>
          <p>OSM In Realtime downloads the minutely changeset feed provided by
          OpenStreetMap and shows the changesets on the map. It isn't really
          intended for any useful mapping purpose. I just thought it would be a
          cool visualization.</p>

          <h2>Created by <a href="https://www.jwestman.net/" target="_blank" rel="noreferrer">James Westman</a></h2>
          <p>I make cool things. Sometimes they even work! You can find me
          on <a href="https://fosstodon.org/@flyingpimonster" target="_blank" rel="noreferrer">Mastodon</a> or <a href="https://twitter.com/FlyingPiMonster" target="_blank" rel="noreferrer">Twitter</a> and you can support my other projects on <a href="https://www.patreon.com/jwestman">Patreon</a> or <a href="https://github.com/sponsors/jameswestman/">GitHub Sponsors</a>.</p>

          <p>Source code is <a href="https://gitlab.com/jwestman/osm-in-realtime" target="_blank" rel="noreferrer">available on GitLab.</a></p>
        </div>
      </div>
    );
  }
}
