import React from 'react';


export default function ChangesetRow(props) {
  let comment = null;
  if (props.changeset.comment) {
    comment = <p>{props.changeset.comment}</p>;
  } else {
    comment = <p><i>no comment</i></p>;
  }

  return (
    <div className="changeset-row">
      <div>
        <a href={`https://www.openstreetmap.org/user/${encodeURIComponent(props.changeset.user)}`}
           target='_blank'
           rel='noreferrer'>
          {props.changeset.user}
        </a>
      </div>
      <a className='changeset-comment'
         href={`https://www.openstreetmap.org/changeset/${encodeURIComponent(props.changeset.id)}`}
         rel='noreferrer'
         target='_blank'>
         {comment}
        </a>
    </div>
  );
}
