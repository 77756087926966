import React from 'react';
import ReactDOM from 'react-dom';
import Confetti from 'react-confetti';

import 'leaflet/dist/leaflet.css';

import About from './about.jsx';
import ChangesetList from './changeset-list.jsx';
import Headerbar from './headerbar.jsx';
import MapView from './map-view.jsx';
import Service from './service.jsx';
import './resources/index.scss';

class OsmInRealtime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAboutDialog: false,
      showChangesetList: false,
      showConfetti: false,
    };
    this._showAboutDialog = this._showAboutDialog.bind(this);
    this._hideAboutDialog = this._hideAboutDialog.bind(this);
    this._toggleChangesetList = this._toggleChangesetList.bind(this);
    this._onChangesetAdded = this._onChangesetAdded.bind(this);
  }

  render() {
    return (
      <div>
        <Headerbar service={this.props.service} showAboutDialog={this._showAboutDialog} toggleChangesetList={this._toggleChangesetList}/>
        <div id="main-content">
          <MapView service={this.props.service}/>
          <ChangesetList service={this.props.service} shown={this.state.showChangesetList}/>
          <Confetti run={this.state.showConfetti} recycle={false} numberOfPieces={4000} tweenDuration={100000} />
        </div>
        <About shown={this.state.showAboutDialog} hideAboutDialog={this._hideAboutDialog}/>
      </div>
    );
  }

  componentDidMount() {
    this.props.service.start();
    this.props.service.register(this._onChangesetAdded);
  }

  componentWillUnmount() {
    this.props.service.stop();
  }

  _showAboutDialog() {
    window.plausible("About Dialog");
    this.setState({ showAboutDialog: true });
  }

  _hideAboutDialog() {
    this.setState({ showAboutDialog: false });
  }

  _toggleChangesetList(show) {
    this.setState((state) => ({
      showChangesetList: !state.showChangesetList
    }));
  }

  _onChangesetAdded() {
    if (this.props.service.showConfetti) {
      this.setState((state) => ({
        showConfetti: true
      }));
    }
  }
}

window.osminrealtime_service = new Service();
ReactDOM.render(
  <OsmInRealtime service={window.osminrealtime_service}/>,
  document.getElementById('root')
);
