import React, {useEffect} from 'react';
import {AttributionControl, CircleMarker, MapContainer, Popup, TileLayer} from 'react-leaflet';
import {useLeafletContext} from '@react-leaflet/core';
import Terminator from '@joergdietrich/leaflet.terminator';

import ChangesetRow from './changeset-row.jsx';

function ReactTerminator(props) {
  const context = useLeafletContext();

  useEffect(() => {
    const terminator = Terminator({
      fillOpacity: 0.1,
      pane: props.pane
    });
    const container = context.layerContainer || context.map;
    container.addLayer(terminator);

    return () => {
      container.removeLayer(terminator);
    };
  });

  return null
}

export default class MapView extends React.Component {
  constructor(props) {
    super(props);
    this._onChangesetAdded = this._onChangesetAdded.bind(this);
    this.state = {
      changesets: []
    };
  }

  render() {
    let changesets = this.state.changesets.map((changeset) =>
      <CircleMarker center={changeset.center}
                    className='changeset-dot'
                    radius={5}
                    key={changeset.id.toString()}
                    stroke={false}
                    fillColor='orangered'
                    fillOpacity={.5}>
        <Popup>
          <ChangesetRow changeset={changeset} key={changeset.id}/>
        </Popup>
      </CircleMarker>
    );
    return (
      <MapContainer id="mapview" center={[0, 0]} zoom={1} maxZoom={6} attributionControl={false}>
        <TileLayer
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          noWrap={true}
          bounds={[[-90, -180], [90, 180]]}
        />
        <AttributionControl position='bottomleft'/>
        <ReactTerminator pane="tilePane" />
        {changesets}
      </MapContainer>
    );
  }

  componentDidMount() {
    this.props.service.register(this._onChangesetAdded);
  }

  componentWillUnmount() {
    this.props.service.unregister(this._onChangesetAdded);
  }

  _onChangesetAdded(changeset) {
    this.setState((state) => {
      let changesets = state.changesets.splice(0);
      changesets.unshift(changeset);

      // clear old changesets
      let time = new Date().getTime() - 600000;
      while (changesets[changesets.length - 1].timeShown < time) {
        changesets.pop();
      }

      return { changesets };
    });
  }
}
